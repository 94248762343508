export const BG_PRIMARY = 'rgba(236,234,223,1)';
export const BG_SECONDARY = 'rgba(163,173,138,1)';

export const TEXT_PRIMARY = 'rgba(176,160,69,1)';
export const TEXT_SECONDARY = 'rgba(236,234,223,1)';
export const TEXT_ALTERNATIVE = 'rgba(118,129,99,1)';

export const BG_NAVIGATION = `#8a976b`;
export const ICON_NAVIGATION = `#FFF`;

export const DEFAULT_BUTTON_PROPS = '';
